<template>
    <div>
        <el-card shadow="hover">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px">{{$t('menu_name.out_product_list')}}</span>
                <el-date-picker
                        style="width: 35%;margin-right: 1%;margin-left: 1%"
                        v-model="value2"
                        type="daterange"
                        align="right"
                        unlink-panels
                        :range-separator="$t('quick.to')"
                        :start-placeholder="$t('quick.start_placeholder')"
                        :end-placeholder="$t('quick.end_placeholder')"
                        :picker-options="pickerOptions"
                        :format="$lang == 2 ? 'yyyy 年 MM 月 dd 日' : 'yyyy يىلى MM ئاينىڭ dd كۈنى'"
                        value-format="yyyy-MM-dd"
                        @change="dateTime">
                </el-date-picker>
            </div>
            <Row :gutter="10">
                <Col :span="24">
                    <el-alert
                            :title="$t('system')"
                            type="info"
                            show-icon
                            :description="$t('system_three_one')"
                            :closable="false">
                    </el-alert>
                </Col>
            </Row>
            <div style="text-align: center">

                <el-table
                        :data="tableData"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="600"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="warehouse_name"
                            :label="$t('customer_table.warehouse_name')">
                    </el-table-column>
                    <el-table-column
                            prop="type_name"
                            :label="$t('menu_name.product_category_title')">
                    </el-table-column>
                    <el-table-column
                            prop="number"
                            :label="$t('customer_table.number_no')">
                    </el-table-column>
                    <el-table-column
                            prop="count"
                            :label="$t('customer_table.case_in_num')">
                    </el-table-column>
                    <el-table-column
                            prop="state_name"
                            :label="$t('customer_table.state')">
                        <template slot-scope="scope">
                            <el-tag type="info" v-if="scope.row.state == 0">{{scope.row.state_name}}</el-tag>
                            <el-tag type="success" v-if="scope.row.state == 1">{{scope.row.state_name}}</el-tag>
                            <el-tag type="warning" v-if="scope.row.state == 2">{{scope.row.state_name}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="crated_at"
                            :label="$t('customer_table.created_at')">
                    </el-table-column>
                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="250">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto">
                                <Col :span="11">
                                    <el-button icon="el-icon-menu" @click="handleQrcode(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.qrCode")}}
                                    </el-button>
                                </Col>
                                <Col :span="11">
                                    <el-button icon="el-icon-view" @click="handleShow(scope.row)" type="success" size="small">
                                        {{$t("customer_table.show_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            :page-size="pagesize"
                            @current-change="current_change">
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog
                :visible.sync="dialog"
                :title="$t('customer_table.share_wechat')"
                center
                :before-close="handleCloce"
                width="30%">
            <div style="text-align: center" class="qrcode-border">
                <vue-qr
                        class="qrcode-border_li"
                        :text="qrcodeValue"
                        ref="image"
                        :size="250"
                        colorDrak="black"
                        colorLight="white"
                        :margin="10">
                </vue-qr>
            </div>
            <div slot="footer">
                <el-button type="primary" icon="el-icon-download" @click="downloadImage">{{$t('download_submit')}}</el-button>
            </div>
            <Spin fix v-if="showSpin"></Spin>
        </el-dialog>
        <el-dialog
                :title="$t('system_shows')"
                :visible.sync="dialogShow"
                :width="$width"
                :before-close="handleCloseShow"
                center>
            <div id="prints" :style="$lang == 2 ? {direction: 'ltr'} : {direction: 'rtl'}">
                <in-product-pre-descrip :data="data"></in-product-pre-descrip>
                <ms-descriptions :data="data.items"></ms-descriptions>
                <div class="barcode_border" style="text-align: center">
                    <barcode class="barcode_border_li" :value="data.number" :options="barcodeOptions"></barcode>
                </div>
            </div>


            <div slot="footer">
                <el-button type="primary" icon="el-icon-printer" v-print="printObj">{{$t('printer')}}</el-button>
            </div>
            <Spin fix v-if="showSpinShow"></Spin>
        </el-dialog>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    import MsDescriptions from "@/components/MsDescriptions";
    import InProductPreDescrip from "@/components/InProductPreDescrip";
    import print from 'vue-print-nb'
    import barcode from '@xkeshi/vue-barcode';
    export default {
        name: "out_product_pre",
        data() {
            return {
                barcodeNum:'',
                barcodeOptions:{
                    displayValue: true, //是否默认显示条形码数据
                    background: '#ffffff', //条形码背景颜色
                    height: '90px',
                    fontSize: '12px',
                    lineColor: '#070707',
                },
                printObj: {
                    id: 'prints',
                    popTitle: '打印', // 打印配置页上方标题
                    previewTitle: '打印', // The title of the preview window. The default is 打印预览
                    previewBeforeOpenCallback (vue) {
                        // console.log('正在加载预览窗口')
                    },
                    previewOpenCallback (vue) {
                        // console.log('已经加载完预览窗口')
                    },
                    beforeOpenCallback (vue) {
                        // console.log('打开之前')
                    },
                    openCallback (vue) {
                        // console.log('执行了打印')
                    },
                    closeCallback (vue) {
                        // console.log('关闭了打印工具')
                    }
                },
                qrcodeValue:'',
                dialog:false,
                pickerOptions: {
                    disabledDate(time) {
                        let curDate = new Date().getTime()

                        let three = 91 * 24 * 3600 * 1000 // 修改30就是天数

                        let threeMonths = curDate - three

                        return time.getTime() > Date.now() || time.getTime() < threeMonths
                    },
                    shortcuts: [{
                        text: this.$i18n.t('quick.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_week'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$i18n.t('quick.last_month'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$i18n.t('quick.last_three_months'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }],
                },

                value2: '',

                ids:'',
                showSpin:true,
                showSpinShow:true,

                dialogShow:false,

                tableLoading:true,

                tableData: [],

                total:0,
                pagesize:10,
                currentPage:1,
                data:'',
            }
        },
        directives:{
            print
        },
        components:{vueQr,MsDescriptions,InProductPreDescrip,barcode},
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                this.http('',1)
            },
            current_change(page){
                this.currentPage = page;
                this.http('',page)
            },
            dateTime(e){
                this.http(e,1)
            },
            http(e,page){
                this.$http.get('cmsOutReceipt/listFor',{
                    params:{
                        date:e,
                        page:page,
                        limit:10
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count == undefined ? 0 : res.count
                });
            },
            handleQrcode(row){
                this.ids = row.id
                this.$http.get('tools/information',{
                    params:{
                        type:2,   //入库
                        id:row.id,
                        lang:this.$lang
                    }
                }).then((res)=>{
                    this.qrcodeValue = res.data.url
                    this.showSpin = false
                })
                this.dialog = true
            },
            handleShow(row){
                this.$http.get('cmsOutReceipt/showFor',{
                    params:{
                        id:row.id
                    }
                }).then((res)=>{
                    this.data = res.data
                    this.showSpinShow = false
                })
                this.dialogShow = true
            },
            downloadImage(){
                let base64 = this.$refs.image.imgUrl;
                let blob = this.convertBase64UrlToBlob(base64)
                let fileName = `分享-<2>-${this.ids}.png`
                let eleLink  = document.createElement('a')
                eleLink.download = fileName
                eleLink.href = URL.createObjectURL(blob)
                eleLink.click()
            },
            convertBase64UrlToBlob(urlData){
                let arr = urlData.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            },
            handleCloce(){
                this.showSpin = true
                this.dialog = !this.dialog
            },
            handleCloseShow(){
                this.showSpinShow = true
                this.dialogShow = !this.dialogShow
            }
        }
    }
</script>

<style scoped>
    .charts-box {
        width: 100%;
        height: 600px;
    }
    /deep/.el-dialog{
        border-radius: 10px;
    }
    .qrcode-border{
        width: 100%;
        height: 100%;
    }
    .qrcode-border_li{
        border : 6px outset #66b1ff;;
        border-radius: 2px;
        box-shadow: 2px 4px 20px #f3f3f3;
    }
    .procedure{
        word-wrap:break-word;
    }

    @page {
        size: auto;
        margin: 3mm;
    }
    @page {
        size: auto;
        margin: 3mm;
    }
    @media print {
        html {
            background-color: #ffffff;
            height: auto;
            margin: 0px;
        }

        body {
            border: solid 1px #ffffff;
            margin: 10mm 15mm 10mm 15mm;
        }
        #prints /deep/table {
            table-layout: auto !important;
        }

        #prints /deep/.el-table__header-wrapper .el-table__header {
            width: 100% !important;
            border: solid 1px #f2f2f2;
        }
        #prints /deep/.el-table__body-wrapper .el-table__body {
            width: 100% !important;
        }
        #prints #pagetable /deep/table {
            table-layout: fixed !important;
        }
    }
    .barcode_border{
        margin-top: 1%;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .barcode_border_li{
        border:3px groove #43d1f5;
        border-radius: 10px;
    }
</style>